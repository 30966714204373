
	@import "../../variables.less";
	@import "../../resource/common.less";

	.mainContent2{
        width: 950px;
    }
	/deep/ .ivu-page-simple .ivu-page-simple-pager input {
		width: 38px;
	}
    .mian_title{
        justify-content: space-between;
        align-items: center;
        .attach{
            margin-right: 15px;
        }
    }
    .repair_content{
        border-radius: 4px;
        background:@contentBg;
        box-shadow: 0 0 10px 1px @boxshadow;
        margin: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
    }
